<template>
  <div id="invoicing-tiss">
    <div class="row">
      <div class="col-12">
        <h3>Configurações gerais</h3>
        <div class="config">
          <b-row>
            <b-col class="pt-2" cols="4">
              <label for="current_batname_establishmentch_number">Nome do estabelecimento de saúde*</label>
              <b-input
                id="name_establishment"
                autocomplete="off"
                type="text"
                class="no-arrow"
                placeholder="Descrever"
                readonly
                :value="clinic.name"
              />
              <div
                v-if="validated && !clinic.name"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
              <div class="custom-invalid-feedback" v-if="batchNumberExist">
                O número do lote informado já existe
              </div>
            </b-col>
            <b-col class="pt-2" cols="4">
              <label for="cnaes_code">Código CNES*</label>
              <b-input
                id="cnaes_code"
                autocomplete="off"
                type="text"
                class="no-arrow"
                placeholder="Descrever"
                v-model="susData.cnaes_code"
              />
              <div
                v-if="validated && !susData.cnaes_code"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
              <div class="custom-invalid-feedback" v-if="batchNumberExist">
                O número do lote informado já existe
              </div>
            </b-col>
            <b-col class="pt-2" cols="4">
              <label for="establishment_acronym">Sigla do Estabelecimento*</label>
              <b-input
                id="establishment_acronym"
                autocomplete="off"
                type="text"
                class="no-arrow"
                placeholder="Descrever"
                v-model="susData.establishment_acronym"
                maxlength="6"
                @input="(val) => (sus.establishment_acronym = sus.establishment_acronym.toUpperCase())"
              />
              <div
                v-if="validated && !susData.establishment_acronym"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </b-col>
            <b-col class="pt-2" cols="6">
              <label for="name_health_body">Nome do órgão de saúde*</label>
              <multiselect
                id="health_body_indicator"
                v-model="selectedNameHealthBody"
                :options="nameHealthBodyOptions"
                label="label"
                track-by="id"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                @input="onSelectNameHealthBody"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <div
                v-if="validated && !susData.name_health_body"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </b-col>
            <b-col class="pt-2" cols="6" v-if="isOtherHealthBody">
              <label for="">&nbsp;</label>
              <b-input
                id="name_health_body"
                autocomplete="off"
                type="text"
                class="no-arrow"
                placeholder="Descrever"
                v-model="susData.name_health_body"
              />
              <div
                v-if="isOtherHealthBody && validated && !susData.name_health_body"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </b-col>
            <b-col class="pt-2" cols="2">
              <label for="health_agency_acronym">Sigla do Órgão de Saúde <span class="opcional">(opcional)</span></label>
              <b-input
                id="health_agency_acronym"
                autocomplete="off"
                type="text"
                class="no-arrow"
                placeholder="Descrever"
                v-model="susData.health_agency_acronym"
                :disabled="!isOtherHealthBody"
              />
            </b-col>
            <b-col class="pt-2" cols="4">
              <label for="health_body_indicator">Indicador do Órgão de Saúde*</label>
              <multiselect
                id="health_body_indicator"
                v-model="selectedHealthBodyIndicator"
                :options="healthBodyIndicatorOptions"
                label="label"
                track-by="id"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                @input="onSelectHealthBodyIndicator"
                :disabled="!isOtherHealthBody"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <div
                v-if="validated && !susData.health_body_indicator"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="col-12 mt-4 mb-2">
        <h3>Configurações BPA</h3>
        <b-row class="config">
          <b-col class="pt-2" cols="3">
            <label for="bpa_version">Versão BPA</label>
            <b-form-input
              id="bpa_version"
              autocomplete="off"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.bpa_version"
              v-mask="'##########'"
              :class="{ error: errors.length > 0 }"
            />
            <div
              v-if="validated && !susData.bpa_version"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="current_bpa_guide_number">Nº guia de BPA atual</label>
            <b-input
              id="current_bpa_guide_number"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.current_bpa_guide_number"
            />
            <div
              v-if="validated && !susData.current_bpa_guide_number"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>            
            <b-col class="pt-2" cols="3">
              <label for="current_bpa_batch_number">N° do lote de BPA atual</label>
              <b-input
                id="current_bpa_batch_number"
                autocomplete="off"
                type="number"
                class="no-arrow"
                placeholder="Descrever"
                v-model="susData.current_bpa_batch_number"
              />
              <div
                v-if="validated && !susData.current_bpa_batch_number"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
              <div class="custom-invalid-feedback" v-if="batchNumberExist">
                O número do lote informado já existe
              </div>
            </b-col>
            <b-col class="pt-2" cols="3">
              <label for="max_number_guides_bpa_batch">N° máx. de guias por lote <span class="opcional">(opcional)</span></label>
              <b-input
                id="max_number_guides_bpa_batch"
                autocomplete="off"
                type="number"
                class="no-arrow"
                placeholder="Descrever"
                v-model="susData.max_number_guides_bpa_batch"
              />
              <div
                v-if="validated && !susData.max_number_guides_bpa_batch"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
              <div class="custom-invalid-feedback" v-if="batchNumberExist">
                O número do lote informado já existe
              </div>
            </b-col>
        </b-row>
      </div>
      <div class="col-12 mt-4 mb-2">
        <h3>Configurações APAC</h3>
        <b-row class="config">
          <b-col class="pt-2" cols="3">
            <label for="apac_version">Versão APAC</label>
            <b-form-input
              id="apac_version"
              autocomplete="off"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.apac_version"
              v-mask="'###############'"
              :class="{ error: errors.length > 0 }"
            />
            <div
              v-if="validated && !susData.apac_version"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="current_apac_guide_number">Nº guia de APAC atual</label>
            <b-input
              id="current_apac_guide_number"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.current_apac_guide_number"
            />
            <div
              v-if="validated && !susData.current_apac_guide_number"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="current_apac_batch_number">N° do lote de APAC atual</label>
            <b-input
              id="currfent_apac_batch_number"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.current_apac_batch_number"
            />
            <div
              v-if="validated && !susData.current_apac_batch_number"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="max_number_guides_apac_batch">N° máx. de guias por lote<span class="opcional">(opcional)</span></label>
            <b-input
              id="max_number_guides_apac_batch"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.max_number_guides_apac_batch"
            />
            <div
              v-if="validated && !susData.max_number_guides_apac_batch"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="col-12 mt-4 mb-2">
        <h3>Configurações AIH</h3>
        <b-row class="config">
          <b-col class="pt-2" cols="3">
            <label for="aih_version">Versão AIH</label>
            <b-form-input
              id="aih_version"
              autocomplete="off"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.aih_version"
              v-mask="'###############'"
              :class="{ error: errors.length > 0 }"
            />
            <div
              v-if="validated && !susData.aih_version"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="current_aih_guide_number">Nº guia de AIH atual</label>
            <b-input
              id="current_aih_guide_number"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.current_aih_guide_number"
            />
            <div
              v-if="validated && !susData.current_aih_guide_number"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="current_aih_batch_number">N° do lote de AIH atual</label>
            <b-input
              id="currfent_aih_batch_number"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.current_aih_batch_number"
            />
            <div
              v-if="validated && !susData.current_aih_batch_number"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>
          <b-col class="pt-2" cols="3">
            <label for="max_number_guides_aih_batch">N° máx. de guias por lote<span class="opcional">(opcional)</span></label>
            <b-input
              id="max_number_guides_aih_batch"
              autocomplete="off"
              type="number"
              class="no-arrow"
              placeholder="Descrever"
              v-model="susData.max_number_guides_aih_batch"
            />
            <div
              v-if="validated && !susData.max_number_guides_aih_batch"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
            <div class="custom-invalid-feedback" v-if="batchNumberExist">
              O número do lote informado já existe
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="col-12 mt-4 mb-2">
        <h3>Configurações de faturamento</h3>
        <b-row class="config">
          <b-col class="pt-2" cols="4">
            <label for="payment_method">Método de pagamento</label>
            <multiselect
              id="payment_method"
              v-model="selectedPaymentMethod"
              @input="onSelectedPaymentMethod"
              track-by="id"
              label="name"
              :options="paymentMethodOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecione"
              class="with-border"
              ref="payment_method"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.payment_method">
              {{ errors.payment_method }}
            </b-form-invalid-feedback>            
          </b-col>
          <b-col class="pt-2" cols="4">
            <label for="bank_account">Conta Bancária</label>
            <multiselect
              id="bank_account"
              v-model="selectedBankAccount"
              @input="onSelectedBankAccount"
              track-by="id"
              label="label"
              :options="bankAccountOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecione"
              class="with-border"
              ref="bank_account"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.bank_account">
              {{ errors.bank_account }}
            </b-form-invalid-feedback>            
          </b-col>
          <b-col class="pt-2" cols="4">
            <label for="installment_quantity">Forma de Pagamento</label>
            <multiselect
              id="installment_quantity"
              v-model="selectedInstallmentQuantity"
              @input="onSelectedInstallmentQuantity"
              track-by="id"
              label="label"
              :options="installmentQuantityOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              placeholder="Selecione"
              class="with-border"
              ref="bank_account"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.installment_quantity">
              {{ errors.installment_quantity }}
            </b-form-invalid-feedback>            
          </b-col>
        </b-row>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>

import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/sus/api'
import moment from 'moment';

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
  },
  props: {
    sus: {
      type: Object,
      required: true
    },
    healthPlanId: String,
    validated: Boolean
  },
  async created() {
    const isLoading = this.$loading.show();
    await this.getInformation();
    await this.getPaymentMethods();
    await this.getBankAccounts();
    isLoading.hide();
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
      batchNumberExist: false,
      susData: [],
      selectedHealthBodyIndicator: null,
      healthBodyIndicatorOptions: [
        {
          "id": "M",
          "label": "Municipal"
        },
        {
          "id": "E",
          "label": "Estadual"
        },
      ],
      selectedNameHealthBody: null,
      nameHealthBodyOptions: [
        {
          "id": "SES",
          "label": "Secretarias Estaduais de Saúde"
        },
        {
          "id": "SMS",
          "label": "Secretarias Municipais de Saúde"
        },
        {
          "id": "other",
          "label": "Outro"
        },
      ],      
      isOtherHealthBody: false,
      errors: {},
      selectedPaymentMethod: null,
      paymentMethodOptions: [],
      selectedBankAccount: null,
      bankAccountOptions: [],
      selectedInstallmentQuantity: null,
      installmentQuantityOptions: [],
    }
  },
  methods: {
    async getInformation() {
      const isLoading = this.$loading.show();
      
      try {
        const { data } = await api.getInformationSusSettingByHealthPlanId(
          this.healthPlanId
        )
        
        this.updateSusData(data);

      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getBankAccounts() {
      try {
        const response = await this.api.getBankAccount(this.clinic.id)
        this.bankAccountOptions = response.data.map(item => {
          return {
            label: `${item.name}`,
            id: item.id
          }
        });
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getPaymentMethods() {
      try {
        await this.api.getPaymentMethodsBillToReceive(this.clinic.id, ['out'])
          .then(res => (this.paymentMethodOptions = res.data.filter(item => item.status && item.name !== 'Crédito do paciente')))
          .catch(err => this.$toast.error(err.message))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    getInstallmentCountOptions() {
      const options = new Array(
        this.selectedPaymentMethod?.installments?.length || 1
      )
        .fill({})
        .reduce((acc, el, index) => {
          acc.push({
            label: this.getPaymentQuantityOptionLabel(index),
            value: index + 1
          })
          return acc
        }, [])
      this.installmentQuantityOptions = options
      if (
        this.installmentQuantityOptions.length === 1 &&
        this.installmentQuantityOptions[0].label === 'À vista'
      ) {
        this.installmentQuantity = this.installmentQuantityOptions[0]
      } else {
        this.installmentQuantity = this.selectedPaymentMethod?.length ? options[this.selectedPaymentMethod?.installments?.length-1] : null
      }
      this.changeDateTypeIfNecessary()
    },
    changeDateTypeIfNecessary() {
      if (this.selectedPaymentMethod && this.selectedPaymentMethod?.id && this.selectedPaymentMethod?.due_date && typeof this.selectedPaymentMethod?.due_date === 'string')  {
        this.selectedPaymentMethod.due_date = this.moment(this.selectedPaymentMethod?.due_date).toDate()
      }
    },
    getPaymentQuantityOptionLabel(index) {
      switch(this.selectedPaymentMethod?.type) {
        case 'cash':
        case 'pix':
        case 'debit':
        case 'debit_card':
          return 'À vista'
        case 'card':
          return index === 0 ? 'À vista' : `${index}x`
        case 'bank_slip':
        case 'check':
          return `${index+1}x`
        default:
          return 'À vista'
      }
    },
    onSelectHealthBodyIndicator(option) {
      this.susData.health_body_indicator = option ? option.id : null;
    },
    onSelectNameHealthBody(option) {      
      if(option.id !== "other") {
        this.susData.health_agency_acronym = option ? option.id : null;
        this.susData.health_body_indicator = option ? (option.id === "SMS" ? "M" : "E") : null;
        this.selectedHealthBodyIndicator = this.healthBodyIndicatorOptions.find(
          item => item.id === this.susData.health_body_indicator
        );
        this.susData.name_health_body = option.label;
        this.isOtherHealthBody = false;
      } else {
        this.susData.health_agency_acronym = null;
        this.susData.health_body_indicator = null;      
        this.selectedHealthBodyIndicator = null;
        this.susData.name_health_body = null;  
        this.isOtherHealthBody = true;        
      }
    },
    onSelectedPaymentMethod(option){
      this.selectedInstallmentQuantity = null;
      this.susData.payment_method_id = option.id;
    },
    onSelectedBankAccount(option){
      this.selectedInstallmentQuantity = null;
      this.susData.bank_account_id = option.id;
    },
    onSelectedInstallmentQuantity(option){
      this.susData.installment_quantity = option.label;
    },
    updateSusData(data) {
      this.susData = {
          'cnaes_code': data.cnaes_code ? data.cnaes_code : '',
          'current_bpa_guide_number' : data.current_bpa_guide_number ? data.current_bpa_guide_number : 1,
          'current_bpa_batch_number' : data.current_bpa_batch_number ? data.current_bpa_batch_number : 1,
          'max_number_guides_bpa_batch' : data.max_number_guides_bpa_batch ? data.max_number_guides_bpa_batch : 1,
          'current_apac_guide_number' : data.current_apac_guide_number ? data.current_apac_guide_number : 1,
          'current_apac_batch_number' : data.current_apac_batch_number ? data.current_apac_batch_number : 1,
          'max_number_guides_apac_batch' : data.max_number_guides_apac_batch ? data.max_number_guides_apac_batch : 1,
          'name_health_body': data.name_health_body,
          'health_agency_acronym': data.health_agency_acronym,
          'health_body_indicator': data.health_body_indicator,
          'apac_version': data.apac_version,
          'bpa_version': data.bpa_version,
          'establishment_acronym': data.establishment_acronym,
          'current_aih_guide_number' : data.current_aih_guide_number ? data.current_aih_guide_number : 1,
          'current_aih_batch_number' : data.current_aih_batch_number ? data.current_aih_batch_number : 1,
          'aih_version': data.aih_version,
          'max_number_guides_aih_batch' : data.max_number_guides_aih_batch ? data.max_number_guides_aih_batch : 1,
          'bank_account_id' : data.bank_account_id,
          'payment_method_id' : data.payment_method_id,
          'installment_quantity' : data.installment_quantity,
        };
        
        this.selectedNameHealthBody = this.nameHealthBodyOptions.find(item => item.label === data.name_health_body);

        if(!this.selectedNameHealthBody){
          this.selectedNameHealthBody = this.nameHealthBodyOptions.find(item => item.id === "other");
          this.isOtherHealthBody = true;
        }

        if(data.bank_account_id){
          this.selectedBankAccount = {
            id: data.bank_account.id,
            label: data.bank_account.name
          }
        }

        if(data.payment_method_id){
          this.selectedPaymentMethod = data.payment_method;
        }

        if(data.installment_quantity){
          this.selectedInstallmentQuantity = {
            id: '',
            label: data.installment_quantity
          };
        }

        this.selectedHealthBodyIndicator = this.healthBodyIndicatorOptions.find(item => item.id === data.health_body_indicator);
    }
  },
  watch: {
    'susData': {
      async handler(value) {
        this.$emit('update-information-sus', this.susData)
      },
      immediate: true,
      deep: true,
    }, 
    'selectedPaymentMethod': {
      async handler(value) {
        value && this.getInstallmentCountOptions()
      },
      immediate: true,
      deep: true,
    },
  }
}
</script>

<style lang="scss">
#invoicing-tiss {
  width: 100%;

  h3 {
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--blue-500);
  }

  .config {
    margin-top: 20px;
    width: 98%;
    padding: 0px;

    label {
      font-family: Nunito Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--dark-blue);
    }

    & > div {
      margin-top: 15px;
    }

    .date-picker {
      border-radius: 8px;
      border: 1px solid var(--neutral-300);

      .date-input {
        width: 100%;
      }
    }

    .multiple {
      border: 1px solid var(--neutral-300);
      border-radius: 8px;
      margin: 4px 0 20px 0;
      height: 38px !important;
      box-sizing: border-box;

      .selectedGuides {
        height: 150%;
        overflow: hidden !important;
      }

      .multiselect__tags {
        height: 100%;
      }
    }
  }

  .checkbox-title {
    margin: 35px 0;
  }

  .info-checkboxes {
    border-radius: 16px;
    border: 1px solid var(--neutral-300);
    padding: 15px;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 2px;

      label {
        font-family: Nunito Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 10px;
        margin-bottom: 0rem !important;
        color: var(--type-active);

        b {
          font-weight: 800 !important;
        }
      }
    }
  }

  .no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .no-arrow {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.opcional{
  color: var(--content-secondary, #8696AC);
  font-size: 12px;
  font-weight: 500;
  padding: 0px 5px;
}
</style>
